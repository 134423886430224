import { Typography, Box, makeStyles } from "@material-ui/core"
import React from "react"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    minHeight: "300px",
    width: "560px",
    padding: "48px",
    flexDirection: "column",
    backgroundColor: "rgba(30, 32, 33, 0.95)",
    [theme.breakpoints.down("md")]: {
      padding: "64px",
      position: "relative",
      margin: "0",
      minHeight: "initial",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "24px",
    },
  },
  button: {
    fontFamily: "Geogrotesque",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "1.6",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: "#010000",
    backgroundColor: "#F26522",
    paddingLeft: "22px",
    paddingRight: "22px",
    paddingTop: "12.5px",
    paddingBottom: "12.5px",
    textDecoration: "none",
    "&:hover": { cursor: "pointer" },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
    },
  },
}))

const ServiceCard = ({ title, icon, description, buttonTitle, navigation }) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <img src={icon} alt={title}></img>
      <Typography variant="h1" color="white" style={{ margin: "16px 0" }}>
        {title}
      </Typography>
      <Typography variant="body1" style={{ maxWidth: "800px" }}>
        {description}
      </Typography>
      <Link
        variant="contained"
        style={{ marginTop: "32px" }}
        to={navigation}
        className={classes.button}
      >
        {buttonTitle}
      </Link>
    </Box>
  )
}

export default ServiceCard
