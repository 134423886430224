import { Box, Typography, makeStyles } from "@material-ui/core"
import React from "react"
import MocapLogo from "../../images/mocap-logo.svg"
import ScanLogo from "../../images/services-scan/scan.svg"
import { EmbedVideo } from "../../components/utils/embed-video"
import LayoutServices from "../../components/layout/layout-services"
import ServiceCard from "../../components/services/servicecard"
import { GetInTouch } from "../../components/services/get-in-touch"
import HeroSection from "../../components/hero-section"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import Fade from "react-reveal/Fade"

const useStyles = makeStyles(theme => ({
  rightImage: {
    top: 0,
    position: "absolute",
    height: "800px",
    width: "100%",
    marginBottom: "32px",
    zIndex: "-1",
    objectFit: "cover",
    paddingLeft: "200px",
    padding: "32px",
    marginLeft: "200px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
      padding: "initial",
      marginBottom: "initial",
      position: "initial",
      paddingLeft: "initial",
      height: "initial",
    },
  },
  leftImage: {
    top: 0,
    position: "absolute",
    height: "800px",
    width: "100%",
    marginBottom: "32px",
    zIndex: "-1",
    objectFit: "cover",
    paddingRight: "200px",
    padding: "32px",
    marginRight: "200px",
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
      padding: "initial",
      marginBottom: "initial",
      position: "initial",
      paddingRight: "initial",
      height: "initial",
    },
  },
  serviceContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "1440px",
    display: "flex",
    alignItems: "center",
    height: "800px",
    margin: "64px",
    padding: "0 32px",
    [theme.breakpoints.down("md")]: {
      margin: "32px 0",
    },
    [theme.breakpoints.down("md")]: {
      margin: "32px 0",
      flexDirection: "column-reverse",
      position: "initial",
      height: "initial",
      padding: "0",
    },
  },
  partnerImage: {
    width: "180px",
    margin: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "120px",
      margin: theme.spacing(2),
    },
  },
  title: {
    margin: "32px 0",
    [theme.breakpoints.down("sm")]: { margin: "24px 0" },
  },
  section: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    margin: "32px 0",
    [theme.breakpoints.down("sm")]: { margin: "16px 0" },
  },
  fade: {
    position: "absolute",
  },
  scanBox: {
    position: "absolute",
    top: "0",
    marginTop: "96px",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      marginTop: "0",
      width: "100%",
    },
  },
  motionBox: {
    position: "absolute",
    top: "0",
    marginTop: "128px",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      marginTop: "0",
      width: "100%",
    },
  },
}))

const ServicesHomePage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        servicesHomePage {
          ServicesHomeLogo {
            url
          }
          ServicesHomeLead
          ServicesHomeButton
          ServicesHeroFirstImage {
            url
          }
          ServicesHeroSecondImage {
            url
          }
          ServicesHeroThirdImage {
            url
          }
          ServicesVidoTitle
          ServicesVideoLink
          ServicesMocapTitle
          ServicesMocapDescription
          ServicesMocapButton
          ServicesMocapBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          ServicesScanTitle
          ServicesScanDescription
          ServicesScanButton
          ServicesScanBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          ServicesPartnersTitle
          ServicesPartnersLogo {
            PartnerLogo {
              url
              imageFile {
                childImageSharp {
                  fixed(width: 165) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
        servicesMocapPage {
          CreateTitle
          CreateLead
          CreateButton
          CreateBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const classes = useStyles()

  const carosuelImages = [
    { image: data.strapi.servicesHomePage.ServicesHeroFirstImage.url },
    { image: data.strapi.servicesHomePage.ServicesHeroSecondImage.url },
    { image: data.strapi.servicesHomePage.ServicesHeroThirdImage.url },
  ]
  return (
    <LayoutServices mainPage>
      <HeroSection
        logo={data.strapi.servicesHomePage.ServicesHomeLogo.url}
        description={data.strapi.servicesHomePage.ServicesHomeLead}
        buttonText={data.strapi.servicesHomePage.ServicesHomeButton}
        slideCount={carosuelImages.length}
        carosuelImages={carosuelImages}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box className={classes.section} id="section">
          <Typography variant="h1" className={classes.title}>
            {data.strapi.servicesHomePage.ServicesVidoTitle}
          </Typography>
          <EmbedVideo src={data.strapi.servicesHomePage.ServicesVideoLink} />
        </Box>
        <Box
          style={{ justifyContent: "flex-start" }}
          className={classes.serviceContainer}
        >
          <Box className={classes.motionBox}>
            <Fade left>
              <ServiceCard
                icon={MocapLogo}
                title={data.strapi.servicesHomePage.ServicesMocapTitle}
                description={
                  data.strapi.servicesHomePage.ServicesMocapDescription
                }
                buttonTitle={data.strapi.servicesHomePage.ServicesMocapButton}
                navigation="/services/mocap"
              />
            </Fade>
          </Box>
          <Img
            fluid={
              data.strapi.servicesHomePage.ServicesMocapBg.imageFile
                .childImageSharp.fluid
            }
            className={classes.rightImage}
          />
        </Box>
        <Box
          style={{ justifyContent: "flex-end" }}
          className={classes.serviceContainer}
        >
          <Box className={classes.scanBox}>
            <Fade right>
              <ServiceCard
                icon={ScanLogo}
                title={data.strapi.servicesHomePage.ServicesScanTitle}
                description={
                  data.strapi.servicesHomePage.ServicesScanDescription
                }
                buttonTitle={data.strapi.servicesHomePage.ServicesScanButton}
                navigation="/services/photo-scan"
              />
            </Fade>
          </Box>
          <Img
            fluid={
              data.strapi.servicesHomePage.ServicesScanBg.imageFile
                .childImageSharp.fluid
            }
            className={classes.leftImage}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={classes.section}
      >
        <Typography variant="h1" align="center" className={classes.title}>
          OUR PARTNERS & CLIENTS
        </Typography>
        <Box
          maxWidth="1240px"
          style={{
            margin: "auto",
            display: "flex",
            flexWrap: " wrap",
            justifyContent: "center",
          }}
        >
          {data.strapi.servicesHomePage.ServicesPartnersLogo.map(
            ({ PartnerLogo }) => (
              <Fade>
                <Img
                  fixed={PartnerLogo.imageFile.childImageSharp.fixed}
                  alt={PartnerLogo}
                  className={classes.partnerImage}
                />
              </Fade>
            )
          )}
        </Box>
      </Box>
      <Fade>
        <GetInTouch create={data.strapi.servicesMocapPage} />
      </Fade>
    </LayoutServices>
  )
}

export default ServicesHomePage
